export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const dayOfMonth = date.getDate();


    const month = date.getMonth();
    const fullMonth = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    const monthName = fullMonth[month];

    const year = date.getFullYear();


    return `${dayOfMonth} ${monthName} ${year}`;
};
