import React from "react"
import styles from './UserContactsModal.module.scss'
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";


interface UserContactsModalParams{
    isActive:boolean
    setIsActive:any
}
const UserContactModal = (props:UserContactsModalParams) => {
    const {isActive,setIsActive} = props
    const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)

    if (!isActive)
    {
        return null
    }

    return (
        <div className={styles.contacts_modal} onClick={()=>setIsActive(!isActive)}>
            <div className={styles.contacts_modal_content} onClick={e => e.stopPropagation()}>
                <div>Телефон</div>
                <div className={styles.user_data}>{userData.phoneNumber}</div>
                <div className={styles.phone_number_div}></div>
                <div>Электронная почта</div>
                <div className={styles.user_data}>{userData.email}</div>
                <h3>Пожалуйста, скажите продавцу, что звоните по объяввлению на сайте</h3>
            </div>
        </div>
    )
};

export default UserContactModal;