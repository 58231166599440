import { fetchSweetsByFilters } from "../../app/actions/catalogActions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styles from './MainPage.module.scss'
import { RootState } from "../../app/store";
import SweetCard from "../../wedgets/SweetCard";
import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import FiltersCard from "../../wedgets/Filters";
import { fetchCategoriesRequest } from "../../app/actions/filtersActions";



const MainPage = () => {
    const dispatch = useAppDispatch()
    const params = useAppSelector((state:RootState)=>state.rootReducer.filters)
    const sweetsArray = useAppSelector((state:RootState) => state.rootReducer.catalog.catalog)
    const isLoading = useAppSelector((state:RootState) => state.rootReducer.catalog.loading)
    
    useEffect(()=>{
        dispatch(fetchCategoriesRequest())
        dispatch(fetchSweetsByFilters(params))        
    },[])

    if(isLoading)
    {
        return(<div>LOADING...</div>)
    }
    
    else if(!isLoading && sweetsArray.length !== 0){
        return (
            <div className={styles.catalog_wrapper}>
                <div className={styles.filters}>
                    <div className={styles.filters_div}>
                        <FiltersCard />
                    </div>
                </div>
                <div className={styles.catalog}>
                    {sweetsArray.length !== 0 && sweetsArray !== undefined 
                    ? 
                    sweetsArray.map((item)=>(
                        <div className={styles.catalog_card}>
                            <Link 
                                key={item.id} 
                                to={"/sweets/"+item.id}>
                                    <SweetCard key={item.id} sweet={item}/>
                            </Link>
                        </div>))
                    : 
                    <h1>while it's empty here</h1>}
            </div>
        </div>
      )
    }
    else{
        return(<div>ERROR</div>)
    }
    
    
};

export default memo(MainPage)