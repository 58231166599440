import React from "react";
import styles from "./TextField.module.scss"


interface TextFieldParams {
    description:string
}


const TextField = (props:TextFieldParams) => {
    const {description} = props

    return (
        <div className="TextField">
            <div className={styles.title}>
                <h2>Описание</h2>
            </div>
            <div className={styles.description}>
                <p>{ description }</p>
            </div>
        </div>
    )
}

export default TextField;