import React, { useEffect, useState} from "react"
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SimpleButton } from "../../shared/ui";
import styles from './UserPage.module.scss'
import FiltersCard from "../../wedgets/Filters";
import StarRating from "../../wedgets/StarRating";
import Comments from "../../wedgets/Comments";
import {formatDate} from "../../shared/ui/NormalizeDate";
import UserContactsModal from "../../wedgets/UserContactsModal";
import AuthModal from "../../wedgets/AuthModal";

const UserPage = () => {
    const noPhotoImage = "../../../images/no-photo-img.svg"
    const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)
    const sweetData = useAppSelector((state: RootState) => state.rootReducer.sweet.sweet)
    const [modalIsActive,setModalIsActive] = useState(false)
    const isAuth = useAppSelector((state:RootState) => state.rootReducer.login.isAuth)



    function openMessageModal() {
        console.log('message to user')
    }

    function openModal(){
        setModalIsActive(true)
    }

    useEffect(()=>{},[userData])

    return (
        <div className={styles.user_page_wrapper}>
            <div className={styles.user_page_filters}>
                <div className={styles.user_page_filters_div}>
                    <FiltersCard />
                </div>
            </div>
            <div className={styles.user_page_content}>
                <div className={styles.user_page_info}>
                    <div className={styles.user_page_avatar_div}>
                        {
                            sweetData.author?.image ?
                                <img className={styles.user_page_avatar} src={process.env.REACT_APP_BASE_URL ?
                                    (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + sweetData.author.image.filename) : ""}
                                     alt='avatar'
                                     onError={({currentTarget}) =>
                                     {currentTarget.onerror = null;
                                         currentTarget.src=noPhotoImage}}/>
                                :
                                <img className={styles.user_page_avatar} src={noPhotoImage} alt={'no-photo'}></img>
                        }
                    </div>
                    <div className={styles.user_page_info_div}>
                            {sweetData?.author ?
                                <h1>{sweetData.author.username}</h1>
                                :
                                <h1>author_username</h1>
                            }
                        <h3>{userData.originalName}</h3>
                        <StarRating initialRating={userData.averageScore}/>
                        <div>Зарегистрирован {formatDate(userData.registrationDate)}</div>
                        <SimpleButton text={'Написать'} onClick={openMessageModal} style={'button_w_430_h_60_bg_white'}/>
                        <div>Контакты:
                        <div className={'userModal'}>
                          <SimpleButton text={'Показать'} onClick={openModal} style={'button_w_430_h_60_bg_orange'}/>
                          <div>
                              {!isAuth?
                              <AuthModal isActive={modalIsActive} setIsActive={setModalIsActive}/>
                              :
                              <UserContactsModal isActive={modalIsActive} setIsActive={setModalIsActive}/>
                              }
                          </div>
                        </div>
                        </div>
                    </div>
                </div>
                    {/*Продукты*/}
                    <div className={styles.user_page_products}>
                        <h2>Товары</h2>
                        {/*<img src={} alt={'caramel'}/>*/}
                        <img src={'corovka.jpg'} alt={'corovka'}/>
                    </div>
                    <div className={styles.user_page_comments}>
                        <Comments/>
                    </div>
            </div>
        </div>
        )
};

export default UserPage;