export const routes = {

    basePath: {
        path: '/'
    },
    // specify item id from db  
    sweets: {
        path: '/sweets/:id',
    },
    profile: {
        path: '/profile'
    },
    userProfile: {
        path: '/user/:id',
    },
    user:{ 
        myProducts:{
                path:'/my-products'
            },
        myBasket:{
                path:'/my-basket'
            },
        myOrders:{
                path:'/my-orders'
            },
        order:{
                path:'/orders/:id'
            },
        myFavorites:{
                path:'/my-favorites'
            },
        },
    moder:{
        sweetQueries:{
            path:'/sweet-queries'
        }
    },
    myMessages:{
        path:'/messages'
    },
    settings:{
        path:'/settings'
    },
    sweetCreation:{
        path:'/new-sweet'
    }
}

