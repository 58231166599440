import React from "react"
import { Link } from "react-router-dom"
import { ProfileModerMenuItems, ProfileUserMenuItems } from "../../../config/profileMenuUtils";
import { routes } from "../../../config/routes";
import styles from './ProfileDropDown.module.scss'
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { logoutRequest } from "../../../app/actions/authActions";
import { RootState } from "../../../app/store";
import {FaRegUser} from 'react-icons/fa6'



interface ProfileDropDownParams {
    role:string
}

const ProfileDropDown = (props: ProfileDropDownParams) => {
    const {role } = props
    const dispatch = useAppDispatch()
    
    const refresh = useAppSelector((state:RootState) => state.rootReducer.login.refreshToken)
    const access = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
    
    function handleLogoutButton(){
        //dispatch(refreshRequest(refresh))
        dispatch(logoutRequest(refresh,access))
    }
    
    // const [selectedOption, setselectedOption] = useState(username)
    return (
            <div className={styles.dropdown}>
                <button className={styles.menu_btn}><Link to={routes.profile.path}>
                    <FaRegUser className={styles.user_icon}/>
                    </Link>
                </button>
                <div className={styles.dropdown_content}>
                    {role === "USER" && ProfileUserMenuItems.map((item) => (<div className={styles.item} key={item.pageName}><Link className={styles.item} to={item.path}>{item.pageName}</Link></div>))}
                    {role === "MODERATOR" && ProfileModerMenuItems.map((item) => (<div className={styles.item} key={item.pageName}><Link className={styles.item} to={item.path}>{item.pageName}</Link></div>))}
                    <button className={styles.exit_button} onClick={() => handleLogoutButton()}><Link to={routes.basePath.path}>Выход</Link></button>
                </div>
        </div>
    )
};

export default ProfileDropDown;
