import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import styles from './OrderPage.module.scss'
import { Link, useParams } from 'react-router-dom';
import { ApproveOrderRequest, CancelOrderRequest, fetchOneOrderDataRequest } from '../../app/actions/orderActions';
import SweetCard from '../../wedgets/SweetCard';
import { SimpleButton } from '../../shared/ui';
import FiltersCard from "../../wedgets/Filters";
import UserInfo from "../../wedgets/UserInfo";
import {formatDate} from "../../shared/ui/NormalizeDate";
const noPhotoImage = "../../../images/no-photo-img.svg"

const OrderPage = () => {
    const params = useParams()
    const sweetData = useAppSelector((state: RootState) => state.rootReducer.sweet.sweet)
    const prodId = params.id
    const dispatch = useAppDispatch()
    const access = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
    const order = useAppSelector((state:RootState) => state.rootReducer.order.order)
    const isLoading = useAppSelector((state:RootState) => state.rootReducer.order.loading)
    
    function handleApproveOrderButton(){
        dispatch(ApproveOrderRequest(prodId,access))
    }
    function handleCancelOrderButton(){
        dispatch(CancelOrderRequest(prodId,access))
    }

    useEffect(()=>{
        if(prodId)
      {
        dispatch(fetchOneOrderDataRequest(prodId,access)) 
      }
    },[])
      if(prodId === undefined)
        {
          return(<div>ERROR</div>)
        }
    
      if(isLoading)
      {
        return(<div>Loading...</div>)
      }
    
      else if(!order && !isLoading){
        return(<div>ERROR</div>)
      }
            else{
            return (
                <div className={styles.page_wrapper}>
                    <div className={styles.filters}>
                        <div className={styles.filters_div}>
                            <FiltersCard />
                        </div>
                    </div>
                    <div className={styles.info}>
                        <h1 style={{marginBottom: 10}}>{order?.sweet?.name}</h1>
                        <div className='inputs'>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Дата выполнения заказа</h2>
                                    <input disabled placeholder={formatDate(order?.plannedPickupAt)}/>
                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Количество товара</h2>
                                    <input type='number' disabled placeholder={order?.quantity?.toString(10)}/>
                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Комментарий к заказу:</h2>
                                    <textarea className={styles.text_area_style} placeholder={'Комментарий к заказу'}/>

                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Выручка с заказа</h2>
                                    <input type='number' disabled placeholder={order?.totalCost?.toString(10)}/>
                                </div>
                        </div>
                        <div className={styles.buttons}>
                            <SimpleButton text='Выполнено' onClick={handleApproveOrderButton} style={'button_w_100pc_h_60_bg_orange'}/>
                            {/* <SimpleButton text='Обновить' onClick={}/> */}
                            <SimpleButton text='Отменить' onClick={handleCancelOrderButton} style={'button_w_100pc_h_60_bg_white'}/>
                        </div>
                        <div className='about_product'>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Текущий рейтинг товара</h2>
                                    <input type='number' disabled placeholder={order?.sweet.averageScore.toString(10)}/>
                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Адрес выдачи товара</h2>
                                    <input type='number' disabled placeholder={order?.sweet.region.name}/>
                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Вес товарной позиции</h2>
                                    <input type='number' disabled placeholder={order?.sweet.weightGrams.toString(10)}/>
                                </div>
                                <div>
                                    <h2 style={{color:'#6A6A6A', marginBottom: 10}}>Цена одной позиции</h2>
                                    <input type='number' disabled placeholder={order?.sweet.cost.toString(10)}/>
                                </div>
                        </div>   

                <div>
                    <h1>Карточка товара</h1>
                    <Link to={"/sweets/"+order.sweet.id}><SweetCard sweet={order.sweet}/></Link>
                </div>
                </div>
                <div className={styles.customer}>
                    {sweetData.author ?
                        <UserInfo
                            username={sweetData.author.username}
                            raiting={sweetData.averageScore}
                            company_name="kruapan"
                            registration_date={sweetData.createdAt}
                            image={process.env.REACT_APP_BASE_URL && sweetData.author.image ?
                                (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + sweetData.author.image.filename)
                                : ""}/>
                        : <div>NOT FOUND</div>}
                            {/*{order.customer.username} /!* Тут по идее надо UserInfo использовать *!/*/}
                        </div>
                </div> 
            )
            }
};
export default OrderPage;