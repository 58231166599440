import React from "react";
import  './SimpleButton.scss'
import {Link} from "react-router-dom";
interface SimpleButtonParams{
    text:string
    onClick:any
    style?:string
    redirectPath?:string
}

export const SimpleButton = (props:SimpleButtonParams) => {
    const {text,onClick,redirectPath,style} = props

    if (!redirectPath) {
        return (<button className={style} onClick={onClick} >{text}</button>)
    }
    else {
        return (<Link to={redirectPath}><button className={style} onClick={onClick}>{text}</button></Link>)
    }

    /*return(
    <div className={'button_container_for_simple_button'}>
        {!redirectPath && <button className={style} onClick={onClick} >{text}</button>}
        {redirectPath && <Link to={redirectPath}><button className={style} onClick={onClick}>{text}</button></Link>}
    </div>
    )*/
}