import styles from './SearchBar.module.scss'
interface SearchBarParams{
    value?:string
    placeholder?: string
    onChange?:any
}
export const SearchBar = (props:SearchBarParams) => {
    const {value,placeholder, onChange} = props
    return (
        <input
            className={styles.input}
            key="search-bar"
            value={value && ''}
            placeholder={placeholder && ''}
        />
    );
}
