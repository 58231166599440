import React, {useState} from "react";
import {Star} from "../../shared/ui"
import styles from '../StarRating/StarRating.module.scss'

export default function StarRating({initialRating = 0 }) {
    const numTotalStars = 5;
    const [numSelectedStars, setNumSelectedStars] = useState(initialRating);

    // const [numHoveringStars, setNumHoveringStars] = useState(0);
    const [isUserHovering, setIsUserHovering] = useState(false);

    function getColor( i:number, numSelectedStars:any) {
        return (i < numSelectedStars) ? "orange" : "grey";
    }

    // return (
    //     <div className="star-rating">
    //         <div onMouseEnter={() => setIsUserHovering(true)} onMouseLeave={() => setIsUserHovering(false)} >
    //             {Array.from({ length: numTotalStars }).map((e, i) =>
    //                 <Star
    //                     key={i}
    //                     color={getColor(isUserHovering, i, numSelectedStars, numHoveringStars)}
    //                     handleSelect={() => setNumSelectedStars(i + 1)}
    //                     handleHover={() => setNumHoveringStars(i + 1)}
    //                 />)}
    //         </div>
    //         {/*<div className="label">rating {numSelectedStars}</div>*/}
    //     </div>
    // );

    return (
        <div className={styles.stars}
            onMouseEnter={() => setIsUserHovering(true)}
            onMouseLeave={() => setIsUserHovering(false)}>
            <div>
                {Array.from({ length: numTotalStars }).map((e, i) => (
                    <Star
                        key={i}
                        color={getColor(i,numSelectedStars)}
                    />
                ))}
                {isUserHovering && (
                    <div className={styles.label}>
                        Рейтинг: {initialRating}
                    </div>
                )}
            </div>
        </div>
    );
}