import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import styles from './MyBasketPage.module.scss'
import { fetchOrderCatalogRequest } from '../../app/actions/orderActions';
import OrderCard from '../../wedgets/OrderCard';
import { Link } from 'react-router-dom';

const MyBasketPage = () => {
    const dispatch = useAppDispatch()
    
    const access = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
    const orders = useAppSelector((state:RootState) => state.rootReducer.orderCatalog.orderCatalog)
    const isLoading = useAppSelector((state:RootState) => state.rootReducer.orderCatalog.loading)

    useEffect(()=>{
      dispatch(fetchOrderCatalogRequest(true,access))
    },[])

    if(isLoading)
    {
        return(<div>LOADING...</div>)
    }
    else if(!isLoading && orders.length !== 0){
      return (
           <div>
            {orders.length !== 0 && orders !== undefined 
                    ? 
                    orders.map((item)=>(
                        <div className={styles.catalog_card}>
                        <Link key={item.id} to={"/orders/"+item.id}><OrderCard key={item.id} order={item}/></Link>
                        </div>))
                    : 
                    <h1>while it's empty here</h1>}
           </div> 
      )}
      else{
        return(<div>ERROR</div>)
    }
    
};
export default MyBasketPage;