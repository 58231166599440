import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { IQuery } from '../../models/models';
import styles from './QueryCard.module.scss'

interface QueryParams{
    query:IQuery

}

const QueryCard = (props:QueryParams) => {
    const {query} = props
    const dispatch = useAppDispatch()
    

    return (
           <div className={styles.card}>
                <img src={query?.author.image.filename}/>
                <div>{query?.author.username}</div>
                <div>{query?.status}</div>
                <div>{query?.moderator.username}</div>
           </div> 
      )
    
};
export default QueryCard;