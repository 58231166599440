
import { IImage, ISweets } from "../../models/models"
import { createSlice } from "@reduxjs/toolkit"

interface imageState{
    loading:boolean
    error:string
    image:IImage
}
const initialState: imageState = {
    loading:false,
    error:'',
    image:{
        id:0,
        filename:'',
        sortOrder:0
    }
}

export const imageSlice = createSlice({

    name:'image',
    initialState,
    reducers:{
        imageUploadStart(state){
            state.loading = true
        },
        imageUploadSuccess(state,action){
            state.loading = false
            state.image.id=action.payload.id
            state.image.filename=action.payload.filename
            state.image.sortOrder=action.payload.sortOrder
        },
        imageUploadError(state,action)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default imageSlice.reducer
export const {imageUploadStart, imageUploadSuccess, imageUploadError} = imageSlice.actions