
import axios from "../../api"
import { AppDispatch } from "../store"

import {IImage, IUser} from "../../models/models"
import { AxiosResponse } from "axios"
import { editProfile, fetchUserDataFailure, fetchUserDataProcess, fetchUserDataSuccess } from "../../entities/slices/userSlice"
import { accessExpiredInCheck } from "./authActions"


export const fetchUserData = (accessToken:string,refreshToken:string) => 

{
    
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(accessExpiredInCheck(refreshToken,accessToken))
            dispatch(fetchUserDataProcess())
            const request = await axios.get('v1/users/me',{
                headers:{
                    Authorization: accessToken
                }
            
            })
            .then((response:AxiosResponse<IUser>) =>{
                dispatch(fetchUserDataSuccess(response.data))
            })
            .catch(error=>{
                dispatch(fetchUserDataFailure(error))
            })
        }
        catch(e){
        }
    }
}

export const patchUserData = (originalName:string|undefined ,phoneNumber:string|undefined,accessToken:string,refreshToken:string,image?:IImage|undefined) => 
{
    const editData = {data:{originalName,phoneNumber,image}}
    return async (dispatch: AppDispatch) => {
        try{
            //dispatch(fetchUserDataProcess())
            dispatch(accessExpiredInCheck(refreshToken,accessToken))
            const request = await axios.patch('v1/users',{
                originalName,
                phoneNumber,
                image     
            },
            {headers:
            {Authorization: accessToken}
            })
            .then((response) =>{
                console.log(response.data)
                dispatch(editProfile(editData.data))
            })
            .catch(error=>{
                console.log(error.message)
            })
            //console.log(response)
        }
        catch(e){

        }
    }
}
