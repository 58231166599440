import { routes } from "./routes"

export const ProfileUserMenuItems = [{
    pageName: 'Мои товары',
    path: routes.user.myProducts.path
},
{
    pageName: 'Мои заказы',
    path: routes.user.myOrders.path
},
{
    pageName: 'Мои любимые',
    path: routes.user.myFavorites.path
},
{
    pageName: 'Сообщения',
    path: routes.myMessages.path
},
{
    pageName: 'Настройки профиля',
    path: routes.settings.path
}
]

export const ProfileModerMenuItems = [
{
    pageName: 'Очередь заказов',
    path: routes.moder.sweetQueries.path
},
{
    pageName: 'Сообщения',
    path: routes.myMessages.path
},
{
    pageName: 'Настройки профиля',
    path: routes.settings.path
}
]

