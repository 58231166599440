import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { IOrder } from '../../models/models';
import UserInfo from '../UserInfo';
import styles from './OrderCard.module.scss'
const noPhotoImage = "../../../images/no-photo-img.svg"

interface QueryParams{
    order:IOrder
}

const OrderCard = (props:QueryParams) => {
    const {order} = props
    const dispatch = useAppDispatch()
    const sweetData = useAppSelector((state: RootState) => state.rootReducer.sweet.sweet)



    return (
           <div className={styles.card}>
            <div>
                <div className='status'>
                    {order.status}
                </div>
                <div className={styles.image_container}>
                    {order?.sweet.mainImage && order.sweet.mainImage !== undefined ? <img className={styles.image_container} src={process.env.FETCH_IMG_PATH + order.sweet.mainImage.filename}/>
                    : <img className={styles.image_container} src={noPhotoImage} alt='no-image'/>}
                </div>
            </div>
            <div className={styles.info}>
                <h1>{order?.sweet?.name}</h1>
                <div className={styles.input_container}>
                    <input className={styles.inputs} disabled placeholder={order?.plannedPickupAt}/>
                    <input type='number' disabled placeholder={order?.quantity?.toString(10)}/>
                </div>
                <div className={styles.customer}>
                    <UserInfo image={process.env.REACT_APP_BASE_URL && sweetData.author?.image ?
                        (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + sweetData.author.image.filename)
                        : ""} username={order.customer.username} company_name={''} registration_date={sweetData.createdAt} raiting={sweetData.averageScore}/>
                    {/*{order.customer.username} /!* Тут по идее надо UserInfo использовать *!/*/}
                </div>
           </div>
        </div> 
      )
    
};
export default OrderCard;