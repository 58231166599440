import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import catalogReducer from '../entities/slices/catalogSlice';
import authReducer from '../entities/slices/authSlice'
import userReducer from '../entities/slices/userSlice'
import orderReducer from '../entities/slices/orderSlice'
import orderCatalogReducer from '../entities/slices/orderCatalogSlice'
import imageReducer from '../entities/slices/imageSlice'
import filterReducer from '../entities/slices/filtersSlice'
import sweetReducer from '../entities/slices/sweetSlice'
import regionsReducer from '../entities/slices/regionsSlice'
import queriesReducer from '../entities/slices/queriesSlice'

const rootReducer  = combineReducers({
  catalog: catalogReducer,
  login: authReducer,
  user: userReducer,
  order:orderReducer,
  orderCatalog:orderCatalogReducer,
  image:imageReducer,
  filters: filterReducer,
  sweet: sweetReducer,
  regions: regionsReducer,
  queries:queriesReducer
})

export const store = configureStore({
  reducer: {
    rootReducer
  },
  });

//export type AppStore = ReturnType<typeof store.getState>
//ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
