import styles from "./SweetCard.module.scss"
import StarRating from "../StarRating";
import { ISweets } from "../../models/models";
import { memo } from "react";

const noPhotoImage = "../../../images/no-photo-img.svg"
interface SweetCardParams {
    sweet:ISweets
}
 
const SweetCard: React.FC<SweetCardParams> = (prop:SweetCardParams) => {
    
    const {sweet} = prop
    return (      
        <div className={styles.card}>
            <div className={styles.image_div}>{sweet?.mainImage && sweet.mainImage !== undefined ? <img className={styles.image_div}

                src={process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + sweet.mainImage.filename) : ""}/>
                :<img className={styles.image_div} src={noPhotoImage} alt='no-image'/> }</div>
            <div className={styles.sweet_info_div}>
                {sweet?.name ? <h2 >{sweet.name}</h2> : <p>Not FOUND</p>}
                {sweet?.author ?  <p>от {sweet.author.username}</p> : <p>Not FOUND</p>}
                {sweet?.shortDescription ? <h5>{sweet?.shortDescription}</h5> : <p>Описание не указано</p>}
                {sweet?.region && !sweet?.region.children ? 
                    <p className={styles.location}> {sweet.region.name} </p> : <p>Регион не указан</p>}
                {sweet?.region && sweet?.region.children ? 
                    <p className={styles.location}> {sweet.region.name}, {sweet?.region.children?.name} </p> : <></> }
                <div className={styles.cost_weight_rate}>
                    <div className={styles.cost_weight_container}>
                        {sweet?.cost ? <h3>{sweet.cost} руб /</h3> : <p>Not FOUND</p>}
                        {sweet?.weightGrams ? <h3>{sweet.weightGrams} г.</h3> : <p>Not FOUND</p>}
                    </div>
                    {sweet?.averageScore ? <StarRating initialRating={sweet.averageScore}/> : <p>Not FOUND</p>}
                </div>
            </div>
        </div>
        
    )
}

export default memo(SweetCard) ;
