import React, {ChangeEvent, useEffect, useState} from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SimpleButton } from "../../shared/ui";
import styles from './ModerPage.module.scss'
import FiltersCard from "../../wedgets/Filters";
import { patchUserData } from "../../app/actions/userActions";
import StarRating from "../../wedgets/StarRating";
import { imageUpload } from "../../app/actions/imageActions";
import { imageUploadSuccess } from "../../entities/slices/imageSlice";
import Comments from "../../wedgets/Comments";
import {formatDate} from "../../shared/ui/NormalizeDate";

const noPhotoImage = "../../../images/no-photo-img.svg"

const ModerPage = () => {
  const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)
  const dispatch = useAppDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [originalName, setOriginalName] = useState(userData.originalName)
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber)
  const accessToken = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
  const refreshToken = useAppSelector((state:RootState) => state.rootReducer.login.refreshToken)
  const [selectedFile, setSelectedFile] = useState<File|null>(null);
  const image = useAppSelector((state:RootState)=>state.rootReducer.image.image)
  const isLoading = useAppSelector((state:RootState)=>state.rootReducer.user.isLoading)


  if(userData.image !== null){
    dispatch(imageUploadSuccess(userData.image))
  }

  function handleEditButton() {
    setIsEdit(!isEdit)

  }
  function saveEditInfo() {

    if (image.id === 0)
    {
    dispatch(patchUserData(originalName,phoneNumber,accessToken,refreshToken))
    setIsEdit(false)
    }
    else{
    dispatch(patchUserData(originalName,phoneNumber,accessToken,refreshToken,image))
    setIsEdit(false)
    }

  }
  function handleUploadImage()
  {
    dispatch(imageUpload(selectedFile,accessToken,refreshToken))
  }

  useEffect(()=>{},[userData])

  const handleFileChange = (events: ChangeEvent<HTMLInputElement>) => {
    if (events.target.files && events.target.files.length > 0) {
      const file = events.target.files[0];
      setSelectedFile(file);
    }
  };

  return(
      <div className={styles.profile_wrapper}>
        <div className={styles.filters}>
          <div className={styles.filters_div}>
            <FiltersCard />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.user_info}>
            <div className={styles.user_avatar_div}>
              { !isEdit ?
                  userData.image ?
                      <img className={styles.user_avatar} src={process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + userData.image.filename) : ""} alt='avatar'
                           onError={({currentTarget}) =>
                           {currentTarget.onerror = null;
                             currentTarget.src=noPhotoImage}}/>
                      :
                      <img className={styles.user_avatar} src={noPhotoImage} alt={'no'}/>
                  :
                  <div>
                    <input type="file" onChange={handleFileChange}/>
                    <SimpleButton text={'Загрузить'} onClick={() => handleUploadImage()}/>
                  </div>
              }
            </div>
            <div className={styles.user_info_div}>
              <h1>{userData.username}</h1>
              <p>{userData.role}</p>
              {!isEdit ? <h3>{userData.originalName}</h3> : <input type="text" placeholder={userData.originalName}
                                                                   onChange={e => setOriginalName(e.target.value)}
              />}
              <StarRating initialRating={userData.averageScore}/>
              <div>Зарегистрирован {formatDate(userData.registrationDate)}</div>
              <SimpleButton style={'button_w_420_h_60_bg_orange'} onClick={!isEdit ? () => handleEditButton() : () => saveEditInfo()} text={!isEdit ? "Редактировать профиль" : "Сохранить"}/>
              <div>Контакты: {!isEdit
                  ?
                  userData.phoneNumber
                  :
                  <input type="text" placeholder={userData.phoneNumber}
                         onChange={e => setPhoneNumber(e.target.value)} />}</div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default ModerPage;