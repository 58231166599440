import axios from "../../api"
import { AppDispatch } from "../store"
import { imageUploadError, imageUploadStart, imageUploadSuccess } from "../../entities/slices/imageSlice"
import { accessExpiredInCheck } from "./authActions"

export const imageUpload = (file:File|null,access:string,refresh:string) =>
{
    return async (dispatch:AppDispatch) =>{
        try{
            dispatch(accessExpiredInCheck(refresh,access))
            dispatch(imageUploadStart())
            const request = await axios.postForm('v1/img',{
                'file':file
            },
            {
                headers:{
                    Authorization:access,
                },
            }
            ).then((response)=>{
                dispatch(imageUploadSuccess(response.data))
                console.log(response.data)

            }).catch((error)=>{
                dispatch(imageUploadError(error.message))
                console.log(error)
            })
        }
        catch(e){
            console.log(e)
        }
    }
}
