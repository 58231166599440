import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IImage } from "../../../models/models";
import style from "./CarouselImages.module.scss"

interface CarouselImagesProps{
  images:any
}

const noPhotoImage = "../../../images/no-photo-img.svg"


function isList(images: any) {
  const images_count = images.length

  if (images_count > 0) {
    return false
  }
  return true
}


function CarouselImages(prop:CarouselImagesProps) {

  const {images} = prop

  const images_count = images.length

  return (
    <div className="box">
      {isList(images) ? <img className={style.no_image} src={noPhotoImage} alt="No images"/> : 
        <Carousel
          infiniteLoop={true}
          showIndicators={true}
          showThumbs={true}
          showStatus={false}
          showArrows={true}
          transitionTime={310}
          swipeable={true}  
          useKeyboardArrows={true} 
        >
          {images && images.map((URL: string, index: number) => (
            <div className={style.img_style} key={index}>
              <img  alt="sample_file" src={URL} key={index} />
            </div>
          ))}
        </Carousel>}
    </div>
  );
}

export default CarouselImages;
