import React, {useEffect, useState } from "react"
import {SimpleButton} from "../../shared/ui";
import styles from './OrderModal.module.scss'
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import 'react-datepicker/dist/react-datepicker.css';
import { ImListNumbered } from "react-icons/im";

import { RootState } from "../../app/store";
import { createNewOrderRequest } from "../../app/actions/orderActions";
import DateTimeInput from "../../shared/ui/DateTimeInput";
import {SlCalender} from "react-icons/sl";
import {IoMdTime} from "react-icons/io";

interface OrderModalParams{
  id:string   // need to fix later
  isActive:boolean
  setIsActive:any
}

const OrderModal = (props:OrderModalParams) => {
    const {id ,isActive,setIsActive} = props
    const dispatch = useAppDispatch()
    const [date,setDate] = useState('')
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [time,setTime] = useState('')
    const [quantity,setQuantity] = useState('')
    const [message,setMessage] = useState("")
    const access = useAppSelector((state:RootState)=>state.rootReducer.login.accessToken)
    const refresh = useAppSelector((state:RootState)=>state.rootReducer.login.refreshToken)

    function handleAcceptButton(){
        const plannedPickUpAt = date + 'T' + time + ':00.000Z'  

        dispatch(createNewOrderRequest(message,plannedPickUpAt,Number.parseInt(quantity),Number.parseInt(id),access,refresh))
    }

    const handleDateChange = (date: Date | null) => {
        // Обработка изменения даты
        console.log(date);
    };

    const handleTimeChange = (hours: number, minutes: number) => {
        // Обработка изменения времени
        console.log(`Selected time: ${hours}:${minutes}`);
    };

    useEffect(()=>{
    if(isActive)
      {setIsActive(true)}
    })
  
    if (!isActive)
    {
    return null
    }

    return (
    <div className={styles.modal} onClick={()=>setIsActive(false)}>
        <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
            <div className={styles.modal_input_fields}>
                <label>Заказ товара от пользователя</label>
                <label>Дата выполенения заказа</label>
            <div className={styles.date_div}>
                <SlCalender style={{width:60, height: 60}}/>
                <input className={styles.date_time_input} type="date" onChange={e => setDate(e.target.value)}/>
                <div className={styles.date_input_field_div}>
                </div>
            </div>
            {/*<DateTimeInput onDateChange={setDate} onTimeChange={setTime}/>*/}
            <label>Время выполенения заказа</label>
            <div className={styles.time_div}>
                <IoMdTime style={{width:60, height: 60}}/>
                <input className={styles.date_time_input} type="time" onChange={e => setTime(e.target.value)}/>
            </div>

            <label>Количество товаров</label>
            <div className={styles.amount_field}>
                <ImListNumbered style={{width:60, height: 60}}/>
                <input className={styles.input_field} type="number"
                       min={1} onChange={e => setQuantity(e.target.value)}
                        placeholder={'1'}/>
            </div>
        </div>
        <div className={styles.comment_block}>
            <textarea className={styles.additional_info_field} placeholder={'Комментарий к заказу...'} onChange={e => setMessage(e.target.value)}/>
            <SimpleButton text="Подтвердить заказ" onClick={handleAcceptButton} style={'button_w_430_h_60_bg_orange'}/>
        </div>
        </div>
    </div>
    )
    };

export default OrderModal;
