import styles from './SweetCreationPage.module.scss'
import FiltersCard from "../../wedgets/Filters";
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { SimpleButton } from '../../shared/ui';
import { imageUpload } from '../../app/actions/imageActions';
import { createSweetRequest } from '../../app/actions/sweetActions';
import UserInfo from "../../wedgets/UserInfo";

export interface IAd{
    images?:[],
    categoryId?:number,
    name?:string,
    cost?:number,
    fullDescription?:string,
    shortDescription?:string,
    mainImageId?:number,
    weightGrams?:number,
    regionId?:number
}


const SweetCreationPage = () => {
    const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)
    const dispatch = useAppDispatch()
    const categories = useAppSelector((state:RootState) => state.rootReducer.filters.categories)
    const regions = useAppSelector((state:RootState) => state.rootReducer.regions.regions)
    const [selectedFile, setSelectedFile] = useState<File|null>(null);
    const accessToken = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
    const refreshToken = useAppSelector((state:RootState) => state.rootReducer.login.refreshToken)
    const mainImageId = useAppSelector((state:RootState) => state.rootReducer.image.image.id)
    const mainImageName = useAppSelector((state:RootState) => state.rootReducer.image.image.filename)
    const [adFields,setAdFields] = useState<IAd>({
        images:[],
        categoryId:0,
        name:'',
        cost:0,
        fullDescription:'',
        weightGrams:0,
        shortDescription:'',
        regionId:0
    })

    
    function handleSubmitButton(){

        dispatch(createSweetRequest(adFields,mainImageId,accessToken))
        console.log(adFields,mainImageId)
        
        
    }
   
    const handleFileChange = (events: ChangeEvent<HTMLInputElement>) => {
        if (events.target.files && events.target.files.length > 0) {
          const file = events.target.files[0];
          setSelectedFile(file);
          console.log(adFields)
          console.log(mainImageId)
          dispatch(imageUpload(file,accessToken,refreshToken))
        }
      };
      function handleImageAcceptButton(){
        
       
      }

      useEffect(()=>{},[mainImageId,adFields])
          return (
          <div className={styles.sweet_wrapper}>
              <div className={styles.filters}>
                  <div className={styles.filters_div}>
                      <FiltersCard />
                  </div>
              </div>
              <div className={styles.sweet_info}>
                  <h1>Создание объявления</h1>
                  <div className={styles.sweet}>
              <div className={styles.pics}>
                  <h2>Добавьте фотографии</h2>
                <div className={styles.sweet_pics}>
                    <input type="file" id={'file_input'} className={styles.file_input} onChange={handleFileChange} />
                    <label htmlFor="file-input" className={styles.custom_file_label}>Выберите файл</label>
                    {/* <button onClick={handleImageAcceptButton}>Save</button> */}
                    {mainImageId !== 0 && <img src={"/img/"+mainImageName}/>}
                </div>
              </div>
              <div className={styles.information}>
                  <div className={styles.title}>
                      <h2>Придумайте название</h2>
                      <input className={styles.input_field} type="text" onChange={(e)=>setAdFields({...adFields,
                          name:e.target.value})} placeholder={'Название конфеты'}/>
                  </div>
                <div className={styles.categories}>
                    <h2>К какой категории относится товар?</h2>
                    <select className={styles.input_field}
                            onChange={(e)=>setAdFields({...adFields,
                            categoryId:Number.parseInt(e.target.value)})}
                            placeholder={'Категория товара'}>
                        {categories ? categories.map((item)=>(
                        <option value={item.id} key={item.id}  
                        >{item.name}</option>)) : <option>Ошибка</option>}
                    </select>
                </div>
                <div className={styles.weight}>
                    <h2>Вес товарной позиции</h2>
                    <input className={styles.input_field} type="number" onChange={(e)=>setAdFields({...adFields,
                            weightGrams:Number.parseInt(e.target.value)})}/>
                </div>
                <div className={styles.cost}>
                    <h2>Цена товара за позицию</h2>
                    <input className={styles.input_field} type="text" onChange={(e)=>setAdFields({...adFields,
                            cost:Number.parseInt(e.target.value)})}
                            placeholder={'2.....23 рубля'}/>
                </div>
                <div className={styles.region}>
                    <h2>Адрес выдачи товара</h2>
                    <select className={styles.input_field} onChange={(e)=>setAdFields({...adFields,
                        regionId:Number.parseInt(e.target.value)})}>
                    {regions ? 
                    regions.map((item)=>(<option key={item.id} value={item.id}>{item.name}</option>))
                    :
                    <option>Ошибка</option>}
                    </select>
                </div>
                <div className={styles.address}>
                    <h2>Заполните адрес</h2>
                    <input className={styles.input_field} type="text" />
                </div>
                <div className={styles.description}>
                    <h2>Добавьте описание</h2>
                    <textarea className={styles.text_area} onChange={(e)=>setAdFields({...adFields,
                        fullDescription:e.target.value})}/>
                </div>

                <div className={styles.description}>
                    <h2>Добавьте короткое описание</h2>
                    <textarea className={styles.text_area} onChange={(e)=>setAdFields({...adFields,
                        shortDescription:e.target.value})}/>
                </div>
              </div>
            </div>
              <SimpleButton text={'Подать объявление'} onClick={handleSubmitButton} style={'button_w_100pc_h_60_bg_orange'}/>
            </div>
          <div className={styles.sweet_user}>
              <UserInfo username={userData.username}
                        company_name="kruapan"
                        registration_date={userData.registrationDate}
                        image={process.env.REACT_APP_BASE_URL ? (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + userData.image.filename) : ""}
                        raiting={userData.averageScore}/>
          </div>
          </div>
        )}

  

export default SweetCreationPage;