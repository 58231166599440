import { ISweets } from "../../models/models"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface sweetState{
    loading:boolean
    error:string
    sweet:ISweets
}
const initialState: sweetState = {
    loading:false,
    error:'',
    sweet:{
        author:{
            id:0,
            image:{
                filename:'',
                id:0,
                sortOrder:0,},
            username:''
        },
        averageScore:0,
        category:{
            id:0,
            name:'',
            sortOrder:0},
        cost:0,
        id:0,
        createdAt:'',
        fullDescription:'',
        images:[],
        mainImage: {
            filename:'',
            id:0,
            sortOrder:0,},
        name:'',
        region:{
            id:0,
            name:'',},
        shortDescription:'',
        myFavorite:false,
        myScore:0,
        weightGrams:0,
    }
}

export const sweetSlice = createSlice({

    name:'sweet',
    initialState,
    reducers:{
        fetchingOneSweetProcess(state){
            state.loading = true
        },
        fetchOneSweetSuccess(state,action){
            state.loading = false
            state.sweet.author = action.payload.author
            state.sweet.name = action.payload.name
            state.sweet.averageScore = action.payload.averageScore
            state.sweet.category = action.payload.category
            state.sweet.cost = action.payload.cost
            state.sweet.createdAt = action.payload.createdAt
            state.sweet.fullDescription = action.payload.fullDescription
            state.sweet.id = action.payload.id
            state.sweet.images = action.payload.images
            state.sweet.mainImage = action.payload.mainImage
            state.sweet.myFavorite = action.payload.myFavorite
            state.sweet.myScore = action.payload.myScore
            state.sweet.region = action.payload.region
            state.sweet.shortDescription = action.payload.shortDescription
        },
        fetchingOneSweetError(state,action)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default sweetSlice.reducer
export const {fetchingOneSweetProcess, fetchOneSweetSuccess, fetchingOneSweetError} = sweetSlice.actions