import { createSlice } from "@reduxjs/toolkit"
import { IUser } from "../../models/models"
import { error } from "console"

interface IUserState{
    isLoading:boolean
    error:string
    userData:IUser
}
const initialState: IUserState =
{
    isLoading:false,
    error:'',
    userData:{
        averageScore:0,
        email:'',
        id:0,
        image:{
            filename:'',
            id:0,
            sortOrder:0},
        originalName:'',
        regions:[{
            id:0,
            name:''
        }],
        phoneNumber:'',
        registrationDate:'',
        role:'',
        totalReviewed:0,
        username:''
    }
}

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        fetchUserDataProcess(state){
            state.isLoading=true
        },
        fetchUserDataFailure(state,action)
        {
            state.isLoading = false
            state.error = action.payload.message
        },
        fetchUserDataSuccess(state,action){
            state.isLoading=false

            state.userData.averageScore = action.payload.averageScore
            state.userData.email = action.payload.email
            state.userData.id = action.payload.id
            state.userData.image = action.payload.image
            state.userData.originalName = action.payload.originalName
            state.userData.regions = action.payload.regions
            state.userData.phoneNumber = action.payload.phoneNumber
            state.userData.registrationDate = action.payload.registrationDate
            state.userData.role = action.payload.role
            state.userData.totalReviewed = action.payload.totalReviewed
            state.userData.username = action.payload.username
        },
        clearUserData(state){
            state = initialState
        },

        editProfile(state, action){
            state.userData.image = action.payload?.image
            state.userData.originalName = action.payload?.originalName
            state.userData.phoneNumber = action.payload?.phoneNumber
        },
        loadImage(state,action){
            state.userData.image.id = action.payload.image.id
            state.userData.image.filename = action.payload.image.filename
            state.userData.image.sortOrder = action.payload.image.sortOrder

        }
    }
})
export default userSlice.reducer
export const {loadImage ,fetchUserDataFailure,fetchUserDataProcess,fetchUserDataSuccess,clearUserData, editProfile} = userSlice.actions

