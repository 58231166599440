import axios from "../../api"
import { fetchingQueries, fetchQueriesSuccess, fetchQueriesError } from "../../entities/slices/queriesSlice"
import { AppDispatch } from "../store"

export const QueriesRequest = (accessToken:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{

            dispatch(fetchingQueries())
            const request = await axios.get('v1/moderator/queries/all',{  
                headers:{
                    Authorization: accessToken
                }
            },)
            .then((response) =>{
                console.log(response.data.username)
                dispatch(fetchQueriesSuccess(response.data))
            })
            .catch((error)=>{
                console.log(error.message)
                dispatch(fetchQueriesError(error))
            })
            //console.log(response)
        }
        catch(e){
            
        }
    }
}

