import { FaStar } from "react-icons/fa";

export function Star({ color = "grey" }) {
    return (
        <FaStar className="star-rating-star"
                color={color}
                // onMouseOver={handleHover}
                // onClick={handleSelect}
        />
    );
}