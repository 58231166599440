import React, { memo } from 'react';
import styles from './RegionDropDawn.module.scss'
import {CiLocationOn} from "react-icons/ci";
import { IRegion } from '../../../models/models';

interface RegionDropDawnParams{
    regions: IRegion[]
}
const DropDown = (props:RegionDropDawnParams) => {
    const {regions} = props
    console.log(regions)
    return (
        <div className={styles.dropdown_div}>
            <CiLocationOn style={{color:"orange", width:22, height:30}}/>
            <select className={styles.dropdown} title={'Regions'}>
            {regions ? regions.map((item)=>(<option className={styles.dropdown_item} key={item.id}>{item.name}</option>)) : <option>ERROR</option> }
            </select>
        </div>
    );
};

export default memo(DropDown) ;