import { useState } from "react";
import { SimpleButton } from "../../shared/ui";
import styles from "./SweetInfo.module.scss"
import StarRating from "../../wedgets/StarRating";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import OrderModal from "../OrderModal";
import AuthModal from "../AuthModal";


const LocationImage = "../../../images/location-pin-svgrepo-com.svg"

interface SweetInfoParams{
    id:string 
    title: string,
    rating: number,
    region: string,
    cost: number,
    weight: number,
}


const SweetInfo = (prop:SweetInfoParams) => {
    const {id, title, rating, region, cost, weight} = prop
    const [modalIsActive,setModalIsActive] = useState(false)
    const isLogedIn = useAppSelector((state:RootState)=>state.rootReducer.login.accessToken)
    function handleOrder () {
        setModalIsActive(true)
    }

    return (
        <div className={styles.sweet_info}>
            <div className={styles.title}>
                <h2>{ title }</h2>
            </div>
            <div className={styles.stars}>
                <StarRating initialRating={rating}/>
            </div>
            <div className={styles.location}>
                <div className={styles.location_logo}>
                    <img src={LocationImage} alt="" width="14.18px" height="19.89px"/>
                </div>
                <p>{ region }</p>
            </div>
            <div className={styles.cost_weight}>
                <div className="cost">
                    <h3><b>{ cost } руб /</b></h3>
                </div>
                <div className={styles.weight}>
                    <h3><b>{ weight } г.</b></h3>
                </div>
            </div>
            <div className={styles.order_button}>
                <SimpleButton style={'button_w_100pc_h_60_bg_orange'} text="Заказать" onClick={handleOrder}/>
            </div>
            {isLogedIn ? <OrderModal id={id} isActive={modalIsActive} setIsActive={setModalIsActive}/>
            : 
            <AuthModal isActive={modalIsActive} setIsActive={setModalIsActive}/>}
        </div>
    )
}

export default SweetInfo;
