
import { IAuthor, ISweets } from "../../models/models"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"



interface orderState{
    loading:boolean
    error:string
    order:{
        createdAt:string
        customer:IAuthor
        id:number
        lastChangedAt:string
        plannedPickupAt:string
        quantity:number
        status:string
        sweet:ISweets
        totalCost:number}
}
const initialState: orderState = {
    loading:false,
    error:'',
    order:{
    createdAt:'',
    customer:{
        id:0,
        image:{
            id:0,
            filename:'',
            sortOrder:0
        },
        username:''
    },
    id:0,
    lastChangedAt:'',
    plannedPickupAt:'',
    quantity:0,
    status:'',
    sweet:{
        averageScore:0,
        cost:0,
        id:0,
        mainImage:{
            id:0,
            filename:'',
            sortOrder:0
        },
        name:'',
        region:{
            id:0,
            name:''
        },
        myFavorite:false,
        myScore:0,
        shortDescription:'',
        weightGrams:0,
    },
    totalCost:0}
}

export const orderSlice = createSlice({

    name:'order',
    initialState,
    reducers:{
        fetchingOrderData(state){
            state.loading = true
        },
        fetchOrderDataSuccess(state,action){
            state.loading = false
            state.order.createdAt=action.payload.createdAt
            state.order.customer=action.payload.customer
            state.order.id=action.payload.id
            state.order.lastChangedAt=action.payload.lastChangedAt
            state.order.plannedPickupAt=action.payload.plannedPickupAt
            state.order.quantity=action.payload.quantity
            state.order.status=action.payload.status
            state.order.sweet=action.payload.sweet
            state.order.totalCost=action.payload.totalCost

        },
        fetchOrderDataError(state,action:PayloadAction<Error>)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default orderSlice.reducer
export const {fetchingOrderData, fetchOrderDataSuccess,fetchOrderDataError} = orderSlice.actions