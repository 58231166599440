import axios from "../../api"
import { fetchCategories } from "../../entities/slices/filtersSlice"
import { AppDispatch } from "../store"


export const fetchCategoriesRequest = () => 
{
    return async (dispatch: AppDispatch) => {
        try{
            const request = await axios.get('v1/categories',{

            })
            .then((response)=>{
                dispatch(fetchCategories(response.data))
            }
            )
            .catch((error)=>{
                console.log(error)
            })
        }
        catch(e){
            
        }
    }
}