import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import styles from './SweetPage.module.scss'
import FiltersCard from "../../wedgets/Filters";
import TextField from "../../shared/ui/TextField";
import SweetInfo from "../../wedgets/SweetInfo";
import UserInfo from "../../wedgets/UserInfo";
import CarouselImages from "../../shared/ui/CarouselImages";
import { useParams } from "react-router";
import { fetchOneSweetRequest } from "../../app/actions/sweetActions";


function SweetImagesList(sweet_data: any){
  const sweet_images_count = sweet_data.images?.length
  
  if (sweet_images_count == 0) {
    return ""
  }
  else {
    const sweet_images = new Array()

    for (const image of sweet_data.images){
      sweet_images.push("https://dev.kruapan.com/api/".slice(0, -4) + 'img/' + image.filename)
    }
    return sweet_images
  }
}


const SweetPage = () => {
  const params = useParams()
  const prodId = params.id
  const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state: RootState) => state.rootReducer.sweet.loading)
  const sweetData = useAppSelector((state: RootState) => state.rootReducer.sweet.sweet)

  const sweet_images = SweetImagesList(sweetData)
  
  console.log(prodId)
  useEffect(()=>{
    if(prodId)
  {
    dispatch(fetchOneSweetRequest(prodId)) 
  }
},[])
  if(prodId === undefined)
    {
      return(<div>ERROR</div>)
    }

  if(isLoading)
  {
    return(<div>Loading...</div>)
  }

  else if(!sweetData && !isLoading){
    return(<div>ERROR</div>)
  }
        else{
          console.log(sweetData)
          return (
          <div className={styles.sweet_wrapper}>
            <div className={styles.filters}>
              <div className={styles.filters_div}>
                <FiltersCard />
              </div>
            </div>
            <div className={styles.sweet}>
              <div className={styles.pics}>
                <div className={styles.sweet_pics}>
                  <CarouselImages images={ sweet_images }/>
                </div>
                <div className={styles.description}>
                  {sweetData.fullDescription ? 
                    <TextField 
                      description={sweetData.fullDescription}/> : 
                    <TextField 
                      description="Описание для этого товара еще не добавлено."/>}
                </div>
              </div>
              <div className={styles.information}>
                <div className={styles.sweet_info}>
                  {sweetData.name  ? 
                    <SweetInfo 
                      id={prodId} 
                      title ={sweetData.name} 
                      region={sweetData.region.name} 
                      cost={sweetData.cost} 
                      weight={sweetData.weightGrams} 
                      rating={sweetData.averageScore}/> : <div>NOT FOUND</div>}
                </div>
                <hr className={styles.hr}/>
                <div className={styles.user_info}>
                  {sweetData.author ? 
                    <UserInfo 
                      username={sweetData.author.username} 
                      raiting={5}
                      company_name="kruapan" 
                      registration_date={sweetData.createdAt} 
                      image={process.env.REACT_APP_BASE_URL && sweetData.author.image ? 
                        (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + sweetData.author.image.filename) 
                      : ""}/> 
                    : <div>NOT FOUND</div>}
                </div>
              </div>
            </div>
          </div>
        )}
};

export default SweetPage;