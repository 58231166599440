import { SimpleButton } from "../../shared/ui";
import styles from "./UserInfo.module.scss"
import StarRating from "../StarRating";
import {Link} from "react-router-dom";
import {routes} from "../../config/routes";
import {formatDate} from "../../shared/ui/NormalizeDate";


const noPhotoImage = "../../../images/no-photo-img.svg"

interface UserInfoParams{
    image: string
    username: string,
    company_name: string,
    registration_date: any,
    raiting: any
}


function imageExists(url: string){
    let image = new Image();
    image.src = url;
    if (!image.complete) {
        return false;
    }
    else if (image.height === 0) {
        return false;
    }
    return true;
}


const UserInfo = (prop:UserInfoParams) => {
    const {username, company_name, registration_date, image, raiting} = prop

    const handleWrite = async () => {

    }

    return (
        <div className={styles.user_info}>
            <div className={styles.title}>
                <Link to={routes.userProfile.path}>
                <h2>Пользователь</h2>
                </Link>
            </div>
            <div className={styles.user_stats}>
                <div className="user-image">
                    <div className={styles.user_avatar_div}>{
                        imageExists(image)?
                        <img className={styles.user_avatar} src={image}
                             alt="avatar"
                             onError={({currentTarget}) =>
                             {currentTarget.onerror = null;
                                 currentTarget.src=noPhotoImage}}/> :
                        <img className={styles.user_avatar_no_avatar} src={noPhotoImage}/>
                    }</div>   
                </div>
                <div className={styles.user_stats_text}>
                    <div className={styles.username}>
                        <p>{ username }</p>
                    </div>
                    <div className={styles.company_name}>
                        <p>{ company_name }</p>
                    </div>
                    <div className={styles.user_stars}>
                        <StarRating initialRating={raiting}/>
                    </div>
                </div>
            </div>
            <div className={styles.registration_date}>
                <p>Зарегистрирован { formatDate(registration_date) }</p>
            </div>
            <div className={styles.communicate_button}>
                <SimpleButton style={'button_w_100pc_h_60_bg_white'} text="Написать" onClick={handleWrite}/>
            </div>
        </div>
    )
}

export default UserInfo;
