import { ISweets } from "../../models/models"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface catalogState{
    loading:boolean
    error:string
    catalog:ISweets[]
    curentPage:number,
    totalElements:number,
    totalPages:number
}
const initialState: catalogState = {
    loading:false,
    error:'',
    catalog:[],
    curentPage:0,
    totalElements:0,
    totalPages:0
}

export const catalogSlice = createSlice({

    name:'catalog',
    initialState,
    reducers:{
        fetchingSweets(state){
            state.loading = true
        },
        fetchSweetsSuccess(state,action){
            state.loading = false
            state.catalog=action.payload.content
            state.curentPage=action.payload.curentPage
            state.totalElements = action.payload.totalElements
            state.totalPages = action.payload.totalPages
        },
        fetchSweetsError(state,action)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default catalogSlice.reducer
export const {fetchingSweets, fetchSweetsSuccess, fetchSweetsError} = catalogSlice.actions