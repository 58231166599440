import { error } from "console"
import { IOrder, ISweets } from "../../models/models"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface orderCatalogState{
    loading:boolean
    error:string
    orderCatalog:IOrder[]
}
const initialState: orderCatalogState = {
    loading:false,
    error:'',
    orderCatalog:[]
}

export const orderCatalogSlice = createSlice({

    name:'orderCatalog',
    initialState,
    reducers:{
        fetchingOrderCatalogStart(state){
            state.loading = true
        },
        fetchOrderCatalogSuccess(state,action){
            state.loading = false
            state.orderCatalog=action.payload.content

        },
        fetchOrderCatalogError(state,action)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default orderCatalogSlice.reducer
export const {fetchingOrderCatalogStart, fetchOrderCatalogSuccess,fetchOrderCatalogError} = orderCatalogSlice.actions