import { ISweetsParams } from "../../config/requestParams"
import { createSlice } from "@reduxjs/toolkit"

const initialState: ISweetsParams = {
    categories:[],
    author:0,
    names:[],
    region:0,
    category:0,
    tags:[],
    page:0,
    size:0,
    sort:[]
}

export const filtersSlice = createSlice({

    name:'filters',
    initialState,
    reducers:{
        setFilters(state,action){
            state.author=action.payload.author
            state.names=action.payload.names
            state.region=action.payload.region
            state.category=action.payload.category
            state.tags=action.payload.tags
            state.page=action.payload.page
            state.size=action.payload.size
            state.sort=action.payload.sort
        },
        fetchCategories(state,action){
            state.categories = action.payload
        },
        
    }
})

export default filtersSlice.reducer
export const {setFilters,fetchCategories} = filtersSlice.actions