import axios from "../../api"
import { fetchOneSweetSuccess, fetchingOneSweetError, fetchingOneSweetProcess } from "../../entities/slices/sweetSlice"
import { IAd } from "../../pages/SweetCreationPage"
import { AppDispatch } from "../store"

export const fetchOneSweetRequest = (id:string | undefined) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            console.log(id)
            dispatch(fetchingOneSweetProcess())
            const request = await axios.get('v1/sweets/'+id,{
                // params:{
                //     id
                // }
            })
            .then((response) =>{
                console.log(response.data.username)
                dispatch(fetchOneSweetSuccess(response.data))
            })
            .catch(error=>{
                console.log(error.message)
                dispatch(fetchingOneSweetError(error))
            })
            //console.log(response)
        }
        catch(e){
            
        }
    }
}

export const createSweetRequest = (fields:IAd,mainImageId:number|undefined,access:string) => 
{
    const {name,cost,weightGrams,shortDescription,fullDescription,regionId,categoryId} = fields
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingOneSweetProcess())
            const request = await axios.post('v1/sweets/',{
                categoryId,cost,fullDescription,"mainImageId":mainImageId,
                name,regionId,shortDescription,weightGrams
            },{
                headers:{
                    Authorization:access
            }})
            .then((response) =>{
                console.log(response.data)
                
            })
            .catch(error=>{
                console.log(error.message)

            })
            //console.log(response)
        }
        catch(e){
            
        }
    }
}