import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import QueryCard from '../../wedgets/QueryCard/index';
import styles from './SweetQueriesPage.module.scss'
import { QueriesRequest } from '../../app/actions/moderatorActions';

const SweetQueriesPage = () => {
    const dispatch = useAppDispatch()
    const queries = useAppSelector((state:RootState) => state.rootReducer.queries.queries)
    const access = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)

    useEffect(()=>{
      dispatch(QueriesRequest(access))
    },[])

      return (
           <div>
            {queries?.map(item => (<QueryCard query={item}/>))}
           </div> 
      )
    
};
export default SweetQueriesPage;