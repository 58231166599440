import React, { memo, useEffect, useState } from 'react';
import {SearchBar} from "../SearchBar";
import SearchButton from "../SearchButton";
import DropDown from "../DropDown";
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../../config/routes";
import styles from './NavBar.module.scss'
import {Link} from "react-router-dom";
import AuthModal from '../../../wedgets/AuthModal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { fetchUserData } from '../../../app/actions/userActions';
import ProfileDropDown from '../ProfileDropDown';
import { fetchRegionsRequest } from '../../../app/actions/regionsActions';
import { LuShoppingCart } from "react-icons/lu";


// const logo = '../../../images/logo.svg'
const logo = '../../../images/logo-plug.svg'


const NavBar = () => {
    const [modalIsActive,setModalIsActive] = useState(false)
    const isLoggedIn = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
    const ProfileUserData = useAppSelector((state:RootState) => state.rootReducer.user.userData)
    const refresh = useAppSelector((state:RootState) => state.rootReducer.login.refreshToken)
    const regions = useAppSelector((state:RootState) => state.rootReducer.regions.regions)

    const dispatch = useAppDispatch()
    if(regions.length === 0)
    {
        dispatch(fetchRegionsRequest())
    }

    useEffect(()=>{
        if(isLoggedIn)
        {
        dispatch(fetchUserData(isLoggedIn,refresh))
        setModalIsActive(false)
        }
    },[isLoggedIn])


    function handlleLoginButton () {
        setModalIsActive(true)
    }

    function button(){
        console.log('тык')
    }

    function handlleBasketButton()
    {

    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <Link to={routes.basePath.path}><img onClick={button} src={logo} alt='logo'/></Link>
            </div>
            <div className={styles.navbar_forms_container}>
                <div className={styles.search_container}>
                    <div className={styles.search_bar_container}>
                        <SearchBar/>
                        <SearchButton/>
                    </div>
                    <DropDown regions={regions}/>
                </div>
                <div className={styles.button_container}>
                    <SimpleButton  redirectPath={isLoggedIn ? routes.sweetCreation.path : undefined}
                                   text={'Подать объявление'}
                                   onClick={isLoggedIn ? button : handlleLoginButton}
                                   style={'button_w_270_h_50_bg_orange'}/>
                    {!isLoggedIn ? <SimpleButton text={'Войти'} style={'button_w_120_h_50_bg_white'} onClick={handlleLoginButton}/>
                    :
                    <div style={{display:"flex"}}>
                        {ProfileUserData.role === "USER" &&
                            <button className={styles.basket_btn} onClick={handlleBasketButton}>
                                <Link to={routes.user.myBasket.path}>
                            <LuShoppingCart className={styles.basket_icon}/>
                            </Link>
                            </button>}
                        <ProfileDropDown role={ProfileUserData.role}/>
                    </div>
                    }

                </div>
            </div>
            <AuthModal isActive={modalIsActive} setIsActive={setModalIsActive}/>
        </div>
    );
};

export default memo(NavBar);