import { createSlice } from "@reduxjs/toolkit"

interface IauthState{
    isAuth:boolean
    accessToken:string
    refreshToken:string
    isLoading:boolean
    error:string
}
const initialState: IauthState = {
    isAuth:false,
    accessToken:'',
    refreshToken:'',
    isLoading:false,
    error:''
}

const authSlice = createSlice({
    name:'login',
    initialState,
    reducers:{
        loginStart(state){
            state.isLoading = true
            state.error = ''
        },
        loginFailure(state,action){
            state.isLoading = false
            console.log(action)
            state.error=action.payload
            
        },
        loginSuccess(state,action){
            state.isLoading = false
            state.error = ''
            state.isAuth = true
            state.accessToken = action.payload.token
            state.refreshToken = action.payload.refresh
        },
        logoutStart(state){
            state.isLoading = true
            state.error = ''
        },
        logoutFailure(state,action){
            state.isLoading = false
            state.error=action.payload
        },
        logoutSuccess(state){
            state.isLoading = false
            state.error = ''
            state.isAuth = false
            state.accessToken = ''
            state.refreshToken = ''

            //console.log(state.refreshToken, state.accessToken)
        },
        refreshSuccess(state,action){
            state.accessToken = action.payload.token
            state.refreshToken = action.payload.refresh
        }
        
    }
})
export default authSlice.reducer
export const {loginFailure,loginStart,loginSuccess,logoutFailure,logoutStart,logoutSuccess, refreshSuccess} = authSlice.actions

