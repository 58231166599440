import { IQuery, ISweets } from "../../models/models"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface queriesState{
    loading:boolean
    error:string,
    queries:IQuery[]
    curentPage:number,
    totalElements:number,
    totalPages:number
}
const initialState: queriesState = {
    loading:false,
    error:'',
    queries:[],
    curentPage:0,
    totalElements:0,
    totalPages:0
}

export const queriesSlice = createSlice({

    name:'queries',
    initialState,
    reducers:{
        fetchingQueries(state){
            state.loading = true
        },
        fetchQueriesSuccess(state,action){
            state.loading = false
            state.queries=action.payload.content
            state.curentPage=action.payload.curentPage
            state.totalElements = action.payload.totalElements
            state.totalPages = action.payload.totalPages
        },
        fetchQueriesError(state,action)
        {
            state.loading = false
            state.error = action.payload.message
        }
    }
})

export default queriesSlice.reducer
export const {fetchingQueries, fetchQueriesSuccess, fetchQueriesError} = queriesSlice.actions