import { useState } from "react"
import { SimpleButton } from "../../shared/ui"
import CheckBoxButton from "../../shared/ui/CheckBoxButton"
import { ISweetsParams } from "../../config/requestParams"
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import styles from "../Filters/Filters.module.scss"


const FiltersCard = () => {
    const [tags,setTags] = useState([''])
    const [params,setParams]=useState<ISweetsParams>({})
    const handleCancelButton = ()=>{
        console.log('cancel')
    }
    const handleAcceptButton = ()=>{
        console.log('accept')
    }
    const chooseFilter = (newTag:string) =>
    {
        
    }
    return (
        <div className={styles.filters}>
            {/* {filters?.map(filter=>(
                <div>
                    <h1>{filter.title}</h1>
                    {filter.list.map(item=>(<CheckBoxButton text={item} name={filter.title} onChange={()=>chooseFilter(item)}/>))}
                </div>
            ))}
            {cost && <div>
                <h1>Цена</h1>
                <input type='text' placeholder='от'/> <input type='text' placeholder='до'/>
            </div>}
            {weight && <div>
                <h1>Вес в граммах</h1>
                <input type='text' placeholder='от'/> <input type='text' placeholder='до'/>
            </div>}
            {rating && <input type='range' min={0} max={5}/>}
            <SimpleButton text={'Показать результаты'} onClick={()=>handleAcceptButton}/>
            <SimpleButton text={'Сбросить фильтры'} onClick={()=>handleCancelButton}/> */}
        </div>


);
};

export default FiltersCard;