import React from "react"
import { Route, Routes } from "react-router-dom";
import { routes } from "../config/routes";
import MainPage from "../pages/MainPage";
import ProfilePage from "../pages/ProfilePage";
import SweetPage from "../pages/SweetPage";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import SweetQueriesPage from "../pages/SweetQueriesPage";
import SweetCreationPage from "../pages/SweetCreationPage";
import UserPage from "../pages/UserPage";
import ModerPage from "../pages/ModerPage";
import AdminPage from "../pages/AdminPage";
import MyOrdersPage from "../pages/MyOrdersPage";
import OrderPage from "../pages/OrderPage";
import MyBasketPage from "../pages/MyBasketPage";



const AppRouting = () => {
  const isAuth = useAppSelector((state:RootState) => state.rootReducer.login.isAuth)
  const role = useAppSelector((state:RootState) => state.rootReducer.user.userData.role)
  return (
    <> 
        <Routes>
            <Route path={routes.basePath.path} element = {<MainPage/>}/>
            <Route path={routes.sweets.path} element = {<SweetPage />}/>
            {isAuth && role === "USER" && <Route path={routes.user.myOrders.path} element = {<MyOrdersPage />}/>}
            {isAuth && role === "USER" && <Route path={routes.user.myBasket.path} element = {<MyBasketPage />}/>}
            {isAuth && role === "USER" && <Route path={routes.user.order.path} element = {<OrderPage />}/>}
            {isAuth && role === "USER" && <Route path={routes.profile.path} element = {<ProfilePage/>}/>}
            {isAuth && role === "MODERATOR" && <Route path={routes.profile.path} element = {<ModerPage/>}/>}
            {isAuth && role === "ADMIN" && <Route path={routes.profile.path} element = {<AdminPage/>}/>}
            <Route path={routes.userProfile.path} element = {<UserPage/>}/>
            {isAuth && role === "MODERATOR" && <Route path={routes.moder.sweetQueries.path} element = {<SweetQueriesPage/>}/>}
            {isAuth && role === "USER" && <Route path={routes.sweetCreation.path} element = {<SweetCreationPage/>}/>}
        </Routes>
    </>
  )
};

export default AppRouting