import StarRating from "../StarRating";
import styles from "./Comments.module.scss"
import React from "react";
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";

const noPhotoImage = "../../../images/no-photo-img.svg"



const Comments = () => {
    const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)

    function normalizeDateComments(dateString:string){
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }


    return (
        <div className={styles.comments_info_div}>
            <div className={styles.comments_user_info_div}>
                <div className={styles.comments_avatar_block}>{
                    userData.image?
                        <img className={styles.comments_image} src={process.env.REACT_APP_BASE_URL && userData.image.filename ? (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + userData.image.filename) : ""} alt='avatar'
                             onError={({currentTarget}) =>
                             {currentTarget.onerror = null;
                                 currentTarget.src=noPhotoImage}} /> :
                        <img className={styles.comments_image} src={noPhotoImage} alt='avatar' />
                }</div>
                <div className={styles.comments_user_info}>
                    <div className={styles.comments_original_name}>{userData.originalName}</div>
                    <div className={styles.comments_user_name}>{userData.username}</div>
                    <div className={styles.comments_registration_date}>{normalizeDateComments(userData.registrationDate)}</div>
                    <div className={styles.comments_rating}>
                        <StarRating initialRating={userData.averageScore}/>
                    </div>
                </div>
            </div>
            <div className={styles.comments_text}>Все было отлично</div>
        </div>
    );
}

export default Comments;