import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import {SimpleButton} from "../../shared/ui";
import styles from './AuthModal.module.scss'
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { loginRequest, regRequest } from "../../app/actions/authActions";
import ReCAPTCHA from 'react-google-recaptcha'
import { RootState } from "../../app/store";
import { FcGoogle } from "react-icons/fc";
import { SlSocialVkontakte } from "react-icons/sl";
import { FaYandex } from "react-icons/fa";

interface AuthModalParams{
  isActive:boolean
  setIsActive:any
}

const AuthModal = (props:AuthModalParams) => {
  const {isActive,setIsActive} = props

  const captchaKey = '6Lelhs8mAAAAAPY17lr2wO3BdAMhSVaDkyRmLiGi'


  const [login,setLogin] = useState('')
  const [username,setUsername] = useState('')
  const [pass,setPass] = useState('')
  const [isReg,setIsReg] = useState(false)
  
  const captchaRef:MutableRefObject<any> = useRef()
  
  const dispatch = useAppDispatch()
  
  const LoginError = useAppSelector((state:RootState) => state.rootReducer.login.error)
  

    useEffect(()=>{
    if(isActive)
      {setIsActive(true)}
  })
  
  if (!isActive)
  {
    return null
  }


  function handleCaptcha()
  {

      console.log('Captcha:',LoginError)
  }

  function handlleLoginButton()
  {
      dispatch(loginRequest(login,pass))
      console.log('login err:  ',LoginError)
  }

  function handleForgotPassword() {
    console.log('восстанови пароль')
  }

  function handlleRegButton(){
    if(captchaRef.current !== null){
      const token = captchaRef.current.getValue()
      dispatch(regRequest(login,pass,username,token))
      captchaRef.current.reset()
    }
  }

  function toggleLoginMode()
  {
      setIsReg(!isReg)
      setLogin('')
      setPass('')
      setUsername('')
  }
  return (
    <div className={styles.modal} onClick={()=>setIsActive(false)}>
      <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
        {/*<div className={styles.modal_services_div}>*/}
        {/*  <h3>Вход через сервисы:</h3>*/}
        {/*  <div className={styles.modal_services}>*/}
        {/*    <FcGoogle className={styles.modal_google}/>*/}
        {/*    <FaYandex className={styles.modal_yandex}/>*/}
        {/*    <div className={styles.modal_vk_div}>*/}
        {/*      <SlSocialVkontakte className={styles.modal_vk}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {!isReg && <div className={styles.modal_input_fields}>
          <input className={styles.modal_input} type="text" placeholder={'E-mail'} onChange={e => setLogin(e.target.value)}/>
          <input className={styles.modal_input} type="password" placeholder={'Пароль'} onChange={e => setPass(e.target.value)} />
          <SimpleButton text={'Забыли пароль?'} onClick={handleForgotPassword} style={'button_forgot_password'}/>
          <SimpleButton text="Войти" onClick={handlleLoginButton} style="button_w_100pc_h_60_bg_orange"/>
          {LoginError && <div>
            <p>Что-то пошло не так</p>
            <p>{LoginError}</p>
          </div>}
          <div className={styles.modal_footer}>
            <h6 style={{fontSize:"x-small", fontWeight: "inherit"}}>У вас нет аккаунта?</h6>
            <SimpleButton text="Создать аккаунт" onClick={toggleLoginMode} style="button_w_100pc_h_60_bg_white"/>
          </div>
        </div>}

        {isReg && <div className={styles.modal_input_fields}>
          <input className={styles.modal_input} type="email" placeholder={'E-mail'} onChange={e => setLogin(e.target.value)}/>
          <input className={styles.modal_input} type="text" placeholder={'Логин'} onChange={e => setUsername(e.target.value)}/>
          <input className={styles.modal_input} type="password" placeholder={'Пароль'} onChange={e => setPass(e.target.value)}/>
          <input className={styles.modal_input} type="password"  placeholder={'Подтвердите пароль'}/>
          <ReCAPTCHA sitekey={captchaKey} onChange={handleCaptcha} ref={captchaRef}/>
          <SimpleButton text="Создать аккаунт" onClick={handlleRegButton} style="button_w_100pc_h_60_bg_orange"/>
          <label>У вас уже есть аккаунт?</label>
          <SimpleButton text="Войти" onClick={toggleLoginMode} style="button_w_100pc_h_60_bg_white"/>
        </div>}
      </div>
    </div>
  )
};

export default AuthModal;
