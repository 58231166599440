import React, {ChangeEvent, useEffect, useState} from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SimpleButton } from "../../shared/ui";
import styles from './ProfilePage.module.scss'
import FiltersCard from "../../wedgets/Filters";
import { patchUserData } from "../../app/actions/userActions";
import StarRating from "../../wedgets/StarRating";
import { imageUpload } from "../../app/actions/imageActions";
import { imageUploadSuccess } from "../../entities/slices/imageSlice";
import Comments from "../../wedgets/Comments";
import { formatDate } from "../../shared/ui/NormalizeDate";
import {Link} from "react-router-dom";
import SweetCard from "../../wedgets/SweetCard";

const noPhotoImage = "../../../images/no-photo-img.svg"

const ProfilePage = () => {
  const userData = useAppSelector((state: RootState) => state.rootReducer.user.userData)
  const dispatch = useAppDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [originalName, setOriginalName] = useState(userData.originalName)
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber)
  const accessToken = useAppSelector((state:RootState) => state.rootReducer.login.accessToken)
  const refreshToken = useAppSelector((state:RootState) => state.rootReducer.login.refreshToken)
  const [selectedFile, setSelectedFile] = useState<File|null>(null);
  const image = useAppSelector((state:RootState)=>state.rootReducer.image.image)
  const sweetsArray = useAppSelector((state:RootState) => state.rootReducer.catalog.catalog)
  // const sweetData = useAppSelector((state: RootState) => state.rootReducer.sweet.sweet)

   if(userData.image !== null){
     dispatch(imageUploadSuccess(userData.image))
  }

  function handleEditButton() {
    setIsEdit(!isEdit)

  }
  function saveEditInfo() {

    if (image.id === 0)
    {
    dispatch(patchUserData(originalName,phoneNumber,accessToken,refreshToken))
    setIsEdit(false)
    }
    else{
    dispatch(patchUserData(originalName,phoneNumber,accessToken,refreshToken,image))
    setIsEdit(false)
    }

  }
  function handleUploadImage()
  {
    dispatch(imageUpload(selectedFile,accessToken,refreshToken))
  }

  useEffect(()=>{},[userData])

  const handleFileChange = (events: ChangeEvent<HTMLInputElement>) => {
    if (events.target.files && events.target.files.length > 0) {
      const file = events.target.files[0];
      setSelectedFile(file);
    }
  };

    return (
    <div className={styles.profile_wrapper}>
      <div className={styles.filters}>
        <div className={styles.filters_div}>
          <FiltersCard />
        </div>
      </div>
      <div className={styles.content}>
      <div className={styles.user_info}>
        <div className={styles.user_avatar_div}>
          { !isEdit ?
              userData.image ? 
              <img className={styles.user_avatar} src={process.env.REACT_APP_BASE_URL && userData.image.filename ? (process.env.REACT_APP_BASE_URL.toString().slice(0, -4) + 'img/' + userData.image.filename) : ""} alt='avatar'
                   onError={({currentTarget}) =>
              {currentTarget.onerror = null;
              currentTarget.src=noPhotoImage}}/>
              :
              <img className={styles.user_avatar} src={noPhotoImage} alt={'no'}/>
            :
            <div className={styles.change_image_button_container}>
              <input type="file" id={'file_input'} className={styles.file_input} onChange={handleFileChange} />
              <label htmlFor="file-input" className={styles.custom_file_label}>Выберите файл</label>
              <SimpleButton text={'Загрузить'} onClick={() => handleUploadImage()} style={'button_w_100pc_h_60_bg_orange'}/>
            </div>
          }
          </div>
        <div className={styles.user_info_div}>
          <h1>{userData.username}</h1>
          {!isEdit ? <h3>{userData.originalName}</h3> : <input className={styles.edit_info_input} type="text" placeholder={userData.originalName}
          onChange={e => setOriginalName(e.target.value)}
          />}
          <StarRating initialRating={userData.averageScore}/>
          <filter>Зарегистрирован {formatDate(userData.registrationDate)}</filter>
          <SimpleButton style={'button_w_420_h_60_bg_orange'} onClick={!isEdit ? () => handleEditButton() : () => saveEditInfo()} text={!isEdit ? "Редактировать профиль" : "Сохранить"}/>
          <h2>Контакты:</h2>
          <h3>{!isEdit
            ?
            userData.phoneNumber
            :
            <input className={styles.edit_info_input} type="text" placeholder={userData.phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)} />}</h3>
        </div>
      </div>
      <div className={styles.products}>
        <h2>Товары</h2>
        <div className={styles.products_list}>
          {sweetsArray.length !== 0 && true
              ?
              sweetsArray.map((item)=>(
                  <div className={styles.catalog_card}>
                    <Link key={item.id} to={"/sweets/"+item.id}><SweetCard key={item.id} sweet={item}/></Link>
                  </div>))
              :
              <h1>while it's empty here</h1>}
        </div>
        {/* <PaginationBar onNextPageClick={handleNextPageClick}
                    onPrevPageClick={handlePrevPageClick}
                    disable={{
                        left: page === 1,
                        right: page === getTotalPageCount(data.length),
                    }}
                    nav={{ current: page, total: getTotalPageCount(data.length) }}/> */}
      </div>
      <div className={styles.comments}>
        <Comments/>
      </div>
      </div>

    </div>
  )
};

export default ProfilePage;