import React from 'react';
import styles from './SearchButton.module.scss'

const search = '../../../images/search.svg'
const SearchButton = () => {
    return (
        <button className={styles.button}><img src={search} alt='search'/></button>
    );
};

export default SearchButton;