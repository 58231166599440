import React, { useEffect } from 'react';
import AppRouting from '../router';
import NavBar from '../shared/ui/NavBar';
import styles from '../shared/styles/index.scss'
import { useAppDispatch, useAppSelector } from './hooks';
import { RootState } from './store';
import { refreshRequest } from './actions/authActions';


function App() {
  
  return (
    <div style={styles}>
      <NavBar/>
      <AppRouting/>
    </div>
    
  );
}

export default App;
