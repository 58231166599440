import axios from "../../api"
import { fetchOrderDataError, fetchOrderDataSuccess, fetchingOrderData } from "../../entities/slices/orderSlice"
import { AppDispatch } from "../store"
import { fetchOrderCatalogError, fetchOrderCatalogSuccess, fetchingOrderCatalogStart } from "../../entities/slices/orderCatalogSlice"
import { accessExpiredInCheck } from "./authActions"

export const fetchOneOrderDataRequest = (id:string|undefined,access:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingOrderData())
            const request = await axios.get('v1/orders/'+id,
            // {params:{
            //     id
            // }}
            {headers:{
                Authorization:access
            }}
            )
            .then((response) =>{
                
                dispatch(fetchOrderDataSuccess(response.data))
            })
            .catch((error)=>{
                console.log(error.message)
                dispatch(fetchOrderDataError(error))
            })
            //console.log(response)
        }
        catch(e){
            
        }
    }
}
//seller:string,page:number,size:number
export const fetchOrderCatalogRequest = (seller:boolean,access:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingOrderCatalogStart())
            const request = await axios.get('v1/orders',{params:{
                seller:seller,
                // page,
                // size,

            },
            headers:{
                Authorization:access
            }
            })
            .then((response) =>{
                
                dispatch(fetchOrderCatalogSuccess(response.data))
            })
            .catch((error)=>{
                console.log(error.message)
                dispatch(fetchOrderCatalogError(error))
            })
        }
        catch(e){
            
        }
    }
}
export const ApproveOrderRequest = (id:string|undefined,access:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingOrderCatalogStart())
            const request = await axios.patch('v1/orders/'+id+'/approve',{},{
            headers:{
                Authorization:access
            }
            })
            .then((response) =>{
                console.log(response)
            })
            .catch((error)=>{
                console.log(error.message)
                
            })
        }
        catch(e){
            
        }
    }
}
export const CancelOrderRequest = (id:string|undefined,access:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingOrderCatalogStart())
            const request = await axios.patch('v1/orders/'+id+'/cancel',{},{headers:{Authorization:access}
            
            })
            .then((response) =>{
                console.log(response)
            })
            .catch((error)=>{
                console.log(error.message)
            })
        }
        catch(e){
            
        }
    }
}

export const createNewOrderRequest = (message:string,plannedPickupAt:string,quantity:number,id:number,access:string,refresh:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(accessExpiredInCheck(refresh,access))
            
            const request = await axios.post('v1/orders',{
                message,
                plannedPickupAt,
                quantity,
                "sweetId":id
            },{
                headers:{
                    Authorization:access
                }
            })
            .then((response) =>{
                console.log(response.statusText)
            })
            .catch((error)=>{
                console.log(error.message)
                
            })
        }
        catch(e){ 
        }
    }
}
