import axios from "../../api"
import { AppDispatch, RootState } from "../store"
import {loginFailure,loginStart,loginSuccess, logoutFailure, logoutStart, logoutSuccess, refreshSuccess} from "../../entities/slices/authSlice"
import { useAppDispatch, useAppSelector } from "../hooks"
import { clearUserData } from "../../entities/slices/userSlice"
let tokenTakingTime = 0

export const loginRequest = (login:string,password:string) => 
{
    console.log(login,password)
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(loginStart())
            const request = await axios.post('v1/auth/login',
            {
                email: login,
                password,
                username: login
        
            },{headers:{
                "X-Forwarded-For":"",
            }}
            ).then(response => {dispatch(loginSuccess(response.data))
                tokenTakingTime = new Date().getTime()

            })
            .catch(error => dispatch(loginFailure(error.response.data.errors[0].message)))
        }
        catch(e){
              
        }
    }
}

export const regRequest = (login:string,password:string,username:string,captcha:string) => 
{
    console.log(login,password)
    return async (dispatch: AppDispatch) => {
        try{
            const request = await axios.post('v1/users',
            {   
                captchaResponse:captcha,
                email: login,
                password,
                username: username
            }
            ).then(response => console.log(response.data))
            .catch(error => console.log(error.message))
        }
        catch(e){
            console.log(e)        
        }
    }
}

export const refreshRequest = (refresh:string) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            const request = await axios.post('v1/auth/refresh',{},{
                params:{
                    'refreshToken':refresh
                },
                headers:{
                'X-Forwarded-For':'empty',
            }}
            ).then((response) => {
                dispatch(refreshSuccess(response.data))
                console.log(response.data.refresh)
            })
            .catch(errors => {dispatch(logoutFailure(errors.message))})
        
        }
        catch(e){
            console.log(e)        
        }
    }
}



export const logoutRequest = (refresh:string,access:string) => 
{
    
    
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(accessExpiredInCheck(refresh,access))
            dispatch(logoutStart())
            const request = await axios.post('v1/auth/logout',{},{
                params:{
                    'refreshToken':refresh
                },
                headers:{
                Authorization: access,
            }}
            ).then(() => {
                dispatch(logoutSuccess())
                dispatch(clearUserData())
            //console.log(refresh)
            })
            .catch(errors => {dispatch(logoutFailure(errors.message))
            console.log(errors.message)}
            )
        }
        catch(e){
            console.log(e)        
        }
    }
}

export const  accessExpiredInCheck = (refresh:string,access:string) => // костыль
{
    console.log(Date.now())
    console.log('---',Date.now()-tokenTakingTime)
    
            return async (dispatch: AppDispatch) => {
            console.log('ACCESS EXPIRED')
            if(access){
            if (Date.now() >= 120000 + tokenTakingTime) {
                const request = await axios.post('v1/auth/refresh',{},{
                    params:{
                        'refreshToken':refresh
                    },
                    headers:{
                    'X-Forwarded-For':'empty',
                }}
                ).then((response) => {
                    dispatch(refreshSuccess(response.data))
                    console.log(response.data.refresh)
                })
                .catch(errors => {dispatch(logoutFailure(errors.message))})
        }      
    }
}
}

