
import { createSlice } from "@reduxjs/toolkit"
import { IRegion } from "../../models/models"

interface regionsState{
    regions:IRegion[]
}

const initialState:regionsState  = {
    regions:[]
}

export const regionsSlice = createSlice({

    name:'regions',
    initialState,
    reducers:{
        setFilters(state,action){
            
        },
        fetchRegions(state,action){
            state.regions=action.payload.regions
        },
        
    }
})

export default regionsSlice.reducer
export const {setFilters,fetchRegions} = regionsSlice.actions