import { Dispatch } from "@reduxjs/toolkit"
import axios from "../../api"
import { AppDispatch } from "../store"
import { ISweetsParams } from "../../config/requestParams"
import { fetchSweetsError, fetchSweetsSuccess, fetchingSweets } from "../../entities/slices/catalogSlice"


export const fetchSweetsByFilters = (params:ISweetsParams) => 
{
    return async (dispatch: AppDispatch) => {
        try{
            dispatch(fetchingSweets())
            const request = await axios.get('v1/sweets',{

                // params:{
                //     params,

                // }
            })
            .then((response)=>{
                dispatch(fetchSweetsSuccess(response.data))
            }
            )
            .catch((error)=>{
                dispatch(fetchSweetsError(error))
                console.log(error)
            })
        }
        catch(e){
            
        }
    }
}

